
import GenericModalResponse from "@/core/types/genericModalResponse";
import { defineComponent, Ref, ref } from "vue";

export default defineComponent({
  name: "GenericModalVue",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    bodyText: {
      type: String,
      default: "",
    },
    headerText: {
      type: String,
      default: "",
    },
    input: {
      type: String,
      default: "",
    },
    isDynamic: {
      type: Boolean,
      default: false, // Default for static components
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close"],
  data() {
    const value: Ref<any> = ref("");
    return {
      showPopup: false,
      value: value,
    };
  },
  watch: {
    async show(val) {
      this.showPopup = val; // in case of static embedding of this component
    },
  },
  mounted() {
    this.value = this.input;
    if (this.isDynamic) this.showPopup = true; // solely to trigger appearing transition
    this.$nextTick(() => {
      const input: any = this.$refs.inputControl;
      input.focus();
      input.select();
    });
  },
  methods: {
    close(isOk: boolean) {
      if (this.isSaving) return;
      if (this.isDynamic) this.showPopup = false;

      const response: GenericModalResponse = {
        isQuestionAnswer: isOk,
        model: isOk ? this.value : null,
      };
      //this.value = null;
      this.$emit("close", isOk ? response : null);
    },
    onYesButtonClick(): void {
      this.close(true);
    },
    onYesCancelClick(): void {
      this.close(false);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") this.close(false);
    },
  },
});
