import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-76e70288"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "tab-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_filters_bar = _resolveComponent("filters-bar")!
  const _component_data_table = _resolveComponent("data-table")!
  const _component_tile_layout = _resolveComponent("tile-layout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_filters_bar, _mergeProps(_ctx.filters, {
      onAggregateChanged: _ctx.onAggregateChanged,
      onDateRangeChanged: _ctx.onDateRangeChanged,
      onApplyFilters: _ctx.onApplyFilters
    }), null, 16, ["onAggregateChanged", "onDateRangeChanged", "onApplyFilters"]),
    _createVNode(_component_tile_layout, {
      columns: _ctx.cols,
      tiles: _ctx.tiles,
      level: _ctx.level,
      "productivity-data-fecther": _ctx.getProductivityData
    }, {
      Items: _withCtx(() => [
        _createVNode(_component_data_table, {
          data: _ctx.data.Items,
          "is-parent-loading": _ctx.isParentLoading
        }, null, 8, ["data", "is-parent-loading"])
      ]),
      PeopleHours: _withCtx(() => [
        _createVNode(_component_data_table, {
          data: _ctx.data.PeopleHours,
          "is-parent-loading": _ctx.isParentLoading
        }, null, 8, ["data", "is-parent-loading"])
      ]),
      ItemsPerPeopleHours: _withCtx(() => [
        _createVNode(_component_data_table, {
          data: _ctx.data.ItemsPerPeopleHours,
          "is-parent-loading": _ctx.isParentLoading
        }, null, 8, ["data", "is-parent-loading"])
      ]),
      _: 1
    }, 8, ["columns", "tiles", "level", "productivity-data-fecther"])
  ]))
}