
import {defineComponent, PropType, Ref, ref, render, createVNode } from "vue";
import {OrderStatusModel} from "@/core/types/orderStatusModel";
import {OrderStatus, OrderStatuses} from "@/core/static-data/orderStatuses";
import GenericModalVue from "@/components/Modal/GenericModal.vue";
import {GetQCUserListViewModel, UserGetQCUserListViewModel} from "@/core/types/user/getQCUserListViewModel";
import PreventUnpaidStatusSelectModalResponse from "@/core/types/modal/preventUnpaidStatusSelectModalResponse";
import {formatDate} from "@telerik/kendo-intl";
import {getUtcDate} from "@/core/utils";
import {Notification as KNotification} from "@progress/kendo-popups-vue-wrapper";
import PreventUnpaidStatusSelectModalVue from "@/components/Modal/PreventUnpaidStatusSelectModal.vue";
import axios from "axios";

export default defineComponent({
  name: 'OrdersBulkEditModal',
  components: {
    "k-notification": KNotification,
    GenericModalVue
  },
  props: {
    orderIds: {
      required: true,
      type: Array as PropType<number[]>,
    },
  },
  emits: ["close"],
  setup() {
    const popupContainer: any = ref(null);
    const selectedOrderStatusId: Ref<number | null> = ref(null);
    const popupNotification: Ref = ref(null);
    const qualityControlUsers: Ref<UserGetQCUserListViewModel[]> = ref([]);
    const qualityControlUserSelected: Ref<UserGetQCUserListViewModel | null> = ref(null);
    const orders: Ref<any[]> = ref([]);
    const isLoading: Ref<boolean> = ref(false);
    const isSaving: Ref<boolean> = ref(false);
    const isQualityControlModalOpen: Ref<boolean> = ref(false);

    // QC modal.
    // const showQcWarningModal: Ref<boolean> = ref(false);
    // const qcWarningModalText: Ref<string | null> = ref(null);

    const paymentDetails: Ref<PreventUnpaidStatusSelectModalResponse | null> = ref(null);
    // Unpaid warning modal.
    //const showUnpaidWarningModal: Ref<boolean> = ref(false);

    return {
      popupContainer,
      selectedOrderStatusId,
      popupNotification,
      adminUsers: qualityControlUsers,
      qualityControlUserSelected: qualityControlUserSelected,
      orders,
      isLoading,
      isSaving,
      isQualityControlModalOpen,

      paymentDetails: paymentDetails,
      // QC modal.

      // Unpaid warning modal.
      // showUnpaidWarningModal,
    };
  },
  data() {
    return {
      OrderStatuses,
      popupNotificationWidget: {} as any,
      show: false, // used solely for the entrance animation purposes
    };
  },
  computed: {
    everyUserAllowsInvoicing(): boolean {
      return !!this.orders?.every(o => o.UserIsForInvoice);
    },
    isPaymentMethodConfigured() {
      return this.isPaymentMethodControlRequired 
        && this.paymentDetails?.user?.Id
        && (this.paymentDetails?.paidByInvoice || this.paymentDetails?.manualPaymentMethod?.Id);
    },
    paymentMethodDetails() {
      return this.paymentDetails == null
        || !this.paymentDetails.paidByInvoice && !this.paymentDetails.manualPaymentMethod && !this.paymentDetails.user
          ? "<configure>"
          : this.paymentDetails.paidByInvoice
            ? 'By Invoice'
            : `${(this.paymentDetails.manualPaymentMethod?.Name || "<configure payment method>")} (${this.paymentDetails.user?.Name || "<configure user>"})`;
    },
    isQualityControlRequired() {
      const qualityControlStatusIds: OrderStatus[] = [
        OrderStatus.FramingComplete,
        OrderStatus.MountingComplete,
        OrderStatus.InDispatch,
        OrderStatus.DispatchQC,

        OrderStatus.Printed,
        OrderStatus.Packed
      ];

      return qualityControlStatusIds.includes(this.selectedOrderStatusId ?? 0);
    },
    isAnyAdminUsersAvailable() {
      return this.adminUsers.length > 0;
    },
    qualityControlWarningMessage() {
      return this.getQualityControlWarningMessage(this.selectedOrderStatusId);
    },
    isQualityControlConfigured() {
      return this.qualityControlUserSelected != null;
    },
    isPaymentMethodControlRequired(): boolean {
      if(!this.selectedOrderStatusId) return false;

      const unpaidStatuses = OrderStatuses.filter(
        status =>
          status.Id === OrderStatus.ProcessingPayment ||
          status.Id === OrderStatus.CardRequired ||
          status.Id === OrderStatus.PaymentFailed ||
          status.Id === OrderStatus.SuspendedOrder ||
          status.Id === OrderStatus.EmbryonicOrder
      );
      const containsUnpaid: boolean = unpaidStatuses.some(
        (status: OrderStatusModel) => this.orders?.some(o => o.Status === status.Id),
      );

      const cancelledStatus = OrderStatuses.find(status => status.Id === OrderStatus.Cancelled);
      const containsUnpaidBeforeCancelled = !!this.orders?.every(o =>
        unpaidStatuses
          .some(status => o.OrderStatusHistory?.length === 0
            || o.OrderStatusHistory.findLast((history: any) => history.NewStatus === cancelledStatus?.Name)?.OldStatus === status.Name),
      );

      const toCancelOrPutOnHold =
        this.selectedOrderStatusId === OrderStatus.Cancelled ||
        this.selectedOrderStatusId === OrderStatus.OnHold;

      if (containsUnpaid && toCancelOrPutOnHold) {
        return false;
      }

      const allCancelled = !!this.orders?.every(o => o.Status == OrderStatus.Cancelled);

      return (
        (containsUnpaid && !toCancelOrPutOnHold) ||
        (containsUnpaidBeforeCancelled && allCancelled)
      );
    },
    isFormValid() {
      return (this.isQualityControlConfigured || !this.isQualityControlRequired)
        && (this.isPaymentMethodConfigured || !this.isPaymentMethodControlRequired)
        && this.selectedOrderStatusId != null;
    }
  },
  mounted() {
    this.popupNotificationWidget = this.popupNotification?.kendoWidget();
    this.show = true;
  },
  created() {
    this.loadInitials();
  },
  methods: {
    async loadInitials() {
      let succeeded = false;
      try {
        if (this.orderIds.length == 0) {
          this.displayError("No orders selected.");
          return;
        }

        this.isLoading = true;
        succeeded = await this.loadQualityControlUsers();

        if (succeeded) {
          succeeded = false;

          const query = this.orderIds.map(orderId => `orderIds=${orderId}`).join("&");
          const response = await axios.get(`/form/pms/GetOrders?${query}`);

          if (!response?.data) {
            return;
          }
          this.orders = response.data;
          succeeded = true;
        }
      } catch(e) {
        this.displayError("Network connection issue." + e);
      } finally {
        this.isLoading = false;
        if (!succeeded) this.close();
      }
    },
    close(isSaved = false) {
      this.show = false;
      this.$emit("close", isSaved);
    },
    oBackDropClick(event: any): void {
      if (event.target.className === "modal-wrapper") {
        this.close();
      }
    },
    isOrderStatusesUnselectable(item: OrderStatusModel): boolean {
      const disabledOrderStatusIds: OrderStatus[] = [
        OrderStatus.ToBeRedone,
        OrderStatus.RefundInitiated,
        OrderStatus.Packed,
        OrderStatus.OrderFailed,
        OrderStatus.PaymentFailed,
        OrderStatus.ProcessingPayment,
        OrderStatus.SuspendedOrder,
        OrderStatus.EmbryonicOrder,
        OrderStatus.CardRequired,
        OrderStatus.ShippingFailed,
      ];

      return disabledOrderStatusIds.includes(item.Id);
    },
    onPaymentMethodEdit(): void {
      const props = {
        allowsInvoicing: this.everyUserAllowsInvoicing,
        input: this.paymentDetails || {
          paidByInvoice: false,
        },
        isDynamic: true, // dynamic creation of the control
        users: this.adminUsers, // what users
        onClose: (responseData: PreventUnpaidStatusSelectModalResponse | null) => {
          if (responseData != null) {
            this.paymentDetails = responseData;
          }

          this.clearPopupContainer();
        },
      };

      const vnode = createVNode(PreventUnpaidStatusSelectModalVue, props);
      vnode.appContext = this.$.appContext;
      render(vnode, this.popupContainer as Element);
    },
    clearPopupContainer() {
      setTimeout(() => render(null, this.popupContainer as Element), 500);
    },
    onOrderStatusChange(e: any): void {
      this.selectedOrderStatusId = Number(e.target.value);
    },
    onUnpaidWarningModalClose(
      response: PreventUnpaidStatusSelectModalResponse | null,
    ): void {
      if (response != null) {
        this.paymentDetails = response;
      }
    },
    getQualityControlWarningMessage(orderStatusId: number | null): string {
      if (orderStatusId == null) return "";

      const texts: { [key: number]: string } = {
        [OrderStatus.FramingComplete]:
          "Has the framing passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.MountingComplete]:
          "Has the mounting passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.InDispatch]:
          "Has the print passed the QC checks? Your name will be recorded as the quality checker",
        [OrderStatus.DispatchQC]:
          "Have you checked the order to ensure: address, number of prints, COAS, Stickers, Inserts, size of prints, framed or unframed parameters are correct?",
        [OrderStatus.MountingFramingQC]:
          "Have you checked the order to ensure: mounting and/or framing parameters are correct?"
      };

      //this.qualityControlWarningMessage = 
      return texts[orderStatusId] || "";
    },
    onQualityUserEdit() {
      this.isQualityControlModalOpen = true;
    },
    onQualityControlModalClose(response: any): void {
      if(response.isQuestionAnswer) {
        if(!response.model) return; // no user selected

        const user: any = this.adminUsers.find(user => user.Id === response.model);
        this.qualityControlUserSelected = user;
      }
      this.isQualityControlModalOpen = false;
    },
    displayError(message: string) {
      this.popupNotificationWidget?.show(message, "error");
    },
    async loadQualityControlUsers(): Promise<boolean> {
      const response: GetQCUserListViewModel = await this.$account.getAdminUsers();

      if (!response) {
        this.displayError(
          `Sorry you don't have permission to change status to Printed or Packed it can make only super admin`,
        );
        return false;
      }

      // if (response.Users.length == 0) {
      //   this.displayError(
      //     `Sorry you don't have permission to change status to Printed or Packed it can make only super admin`,
      //   );
      //   return false;
      // }

      this.adminUsers = response.Users;
      return true;
    },
    async saveChanges(): Promise<void> {
      if (!this.orders || !this.isFormValid) return;

      this.isSaving = true;

      /// applying changes
      /// quality control
      const orders: any[] = JSON.parse(JSON.stringify(this.orders));
      if (this.isQualityControlRequired) {
        const userId = this.qualityControlUserSelected?.Id; // we checked before entering the method. user must be available here!
        if (this.selectedOrderStatusId === OrderStatus.InDispatch) {
          orders.forEach(order => (order.PrintQCUserId = userId));
          orders.forEach(order => (order.CheckedPrintQCUserId = userId));
        }
        if (this.selectedOrderStatusId === OrderStatus.DispatchQC) {
          orders.forEach(order => order.DispatchQCUserId = userId);
          orders.forEach(order => order.CheckedDispatchQCUserId = userId);
        }
        if ([OrderStatus.FramingComplete, OrderStatus.MountingComplete].includes(this.selectedOrderStatusId ?? 0)) {
          orders.forEach(order => (order.FramingMountingQCUserId = userId));
          orders.forEach(order => (order.CheckedFramingMountingQCUserId = userId));
        }
      }
      /// payment method control
      if (this.isPaymentMethodControlRequired) {
        if (!this.paymentDetails?.paidByInvoice) {
          orders.forEach(order => (order.IsManualPaid = true));
        } else {
          orders.filter(order => order.OrderTypeId === 11).forEach(order => order.IsASFForInvoice = true);
          orders.filter(order => !order.IsFinalised).forEach(order => order.IsInHouseForInvoice = true);
          orders.filter(order => order.OrderTypeId !== 11).forEach(order => order.IsPrintForInvoice = true);
        }

        orders.forEach(order => order.PaidDate =
          !this.paymentDetails?.paidByInvoice
            ? formatDate(this.paymentDetails?.paymentDate || new Date(), "dd/MM/yyyy")
            : formatDate(getUtcDate(), "dd/MM/yyyy"));
            
        orders.forEach(order => order.IsAddedManualPayment = true);
        orders.forEach(order => order.ManualPaymentMethod = this.paymentDetails?.manualPaymentMethod?.Id ?? 0);
        orders.forEach(order => order.ManualPaymentUserId = this.paymentDetails?.user?.Id);
        orders.forEach(order => order.ManualPaymentNote = this.paymentDetails?.note || "");
      }
      /// applying changes ^^^

      orders.forEach(order => (order.Status = this.selectedOrderStatusId));

      try {
        const batchSize = 5;

        for (let i = 0; i < orders.length; i+=batchSize) {
          const batch = orders.slice(i, i + batchSize);
          await axios.post("/form/pms/SetOrders", batch);
        }

        this.popupNotificationWidget?.show("Orders were updated", "success");
        this.close(true);
      } catch {
        this.popupNotificationWidget?.show("Something went wrong", "error");
      } finally {
        this.isSaving = false;
      }
    },
  },
});
