import {
  BreakdownItem,
  ExportValueOption,
  RevenueAndCommissionConfig,
  RevenueAndCommissionsItem,
  RevenueAndCommissionSummaryItem,
  ClientManagerItem,
  ClientManagerType,
  ClientTypeHistoryEntry,
  ClientManagerHistoryEntry,
  Client,
  ClientCommissionAudit,
  ClientTransferredStatusParams,
  AuditedCommandParams,
  ClientManagerInfoParams,
  ClientToClientManagerParams,
  RecalculateCommissionsParams,
} from "./typesAndConstants";
import axios from "axios";

import { strIsoOfDate } from "./helpers";

type QueryString = Record<any, any>;

const queryStringOf = (parms: QueryString) =>
  Object.entries(parms)
    .map(([k, v]) => `${k}=${v}`)
    .join("&");

// ----------------------------------------------------------------------------

const formUrl = (actionName: string, baseUrl = "/form/pms"): string => {
  return `${baseUrl}/${actionName}`;
};

// ----------------------------------------------------------------------------

const formQuery = (
  actionName: string,
  queryStringParams: QueryString | null = null,
  baseUrl = "/form/pms",
): string => {
  const queryString = queryStringParams
    ? `?${queryStringOf(queryStringParams)}`
    : "";

  return `${formUrl(actionName, baseUrl)}${queryString}`;
};

// ----------------------------------------------------------------------------

export async function getClientManagerClients(
  clientManagerUserId: number,
): Promise<ClientManagerItem[]> {
  const url = formQuery("GetClientManagerClients", { clientManagerUserId });

  const response = await axios.get<ClientManagerItem[]>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsConfig(
  clientManagerType: ClientManagerType,
): Promise<RevenueAndCommissionConfig> {
  const url = formQuery("GetRevenueAndCommissionsConfig", {
    clientManagerType,
  });
  const response = await axios.get<RevenueAndCommissionConfig>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissions(
  from: Date,
  to: Date,
  clientManagerType: ClientManagerType,
): Promise<RevenueAndCommissionsItem[]> {
  const url = formQuery("GetRevenueAndCommissions", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerType,
  });

  const response = await axios.get<RevenueAndCommissionsItem[]>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsSummary(
  from: Date,
  to: Date,
  clientManagerType: ClientManagerType,
): Promise<RevenueAndCommissionSummaryItem[]> {
  const url = formQuery("GetRevenueAndCommissionsSummary", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerType,
  });

  const response = await axios.get<RevenueAndCommissionSummaryItem[]>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getSpecialAccountRevenueAndCommissionsSummary(
  from: Date,
  to: Date,
  clientManagerType: ClientManagerType,
): Promise<RevenueAndCommissionSummaryItem[]> {
  const url = formQuery("GetRevenueAndCommissionsSpecialAccountSummary", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerType,
  });

  const response = await axios.get<RevenueAndCommissionSummaryItem[]>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsClientManagerBreakdown(
  from: Date,
  to: Date,
  clientManagerUserId: number | null = null,
): Promise<BreakdownItem[]> {
  clientManagerUserId ??= 1;
  const url = formQuery("GetRevenueAndCommissionsClientManagerBreakdown", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerUserId,
  });

  const response = await axios.get<BreakdownItem[]>(url);

  return response.data;
}

// ----------------------------------------------------------------------------

export async function getClientManagerClientsCsv(
  clientManagerUserId: number,
): Promise<any> {
  const url = formQuery("GetClientManagerClientsCsv", {
    clientManagerUserId,
  });

  const response = await axios.get<any[]>(url);

  return response;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsCsv(
  from: Date,
  to: Date,
  valueOption = ExportValueOption.Revenue,
  clientManagerType: ClientManagerType,
): Promise<any> {
  const url = formQuery("GetRevenueAndCommissionsCsv", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    valueOption,
    clientManagerType,
  });

  const response = await axios.get<any>(url);

  return response;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsSummaryCsv(
  from: Date,
  to: Date,
  valueOption = ExportValueOption.Revenue,
  clientManagerType: ClientManagerType,
): Promise<any> {
  const url = formQuery("GetRevenueAndCommissionsSummaryCsv", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    valueOption,
    clientManagerType,
  });

  const response = await axios.get<any>(url);

  return response;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsSummarySourceDataCsv(
  from: Date,
  to: Date,
  clientManagerType: ClientManagerType,
) {
  const url = formQuery("GetRevenueAndCommissionsSummarySourceDataCsv", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerType,
  });

  const response = await axios.get<any>(url);

  return response;
}

// ----------------------------------------------------------------------------

export async function getRevenueAndCommissionsClientManagerBreakdownCsv(
  from: Date,
  to: Date,
  clientManagerUserId: number | null = null,
): Promise<any> {
  const url = formQuery("GetRevenueAndCommissionsClientManagerBreakdownCsv", {
    from: strIsoOfDate(from),
    to: strIsoOfDate(to),
    clientManagerUserId,
  });

  const response = await axios.get<any>(url);

  return response;
}

// ----------------------------------------------------------------------------

export async function setClientTransferredStatus(
  parms: AuditedCommandParams<ClientTransferredStatusParams>,
): Promise<ClientManagerItem> {
  const response = await axios.post<any>(
    formUrl(`SetClientTransferredStatus`),
    parms,
  );

  return response.data;
}

// ----------------------------------------------------------------------------

export async function recalculateCommissions(
  parms: AuditedCommandParams<RecalculateCommissionsParams>,
): Promise<void> {
  const response = await axios.post<any>(
    formUrl(`RecalculateCommissions`),
    parms,
  );
  if (response.status != 200) {
    console.error(response);
    throw Error("recalculateCommissions error");
  }
  return response.data;
}

// ----------------------------------------------------------------------------

export async function getClientTypeHistory(
  clientId: number,
): Promise<ClientTypeHistoryEntry[]> {
  const url = formQuery("GetClientTypeHistory", { clientId });
  const response = await axios.get<any>(url);

  if (response.status == 200) {
    const result = response.data.map((item: any) => {
      const to = item.To ? new Date(Date.parse(item.To)) : null;
      return {
        Id: item.Id,
        ClientType: item.ClientType,
        From: new Date(Date.parse(item.From)),
        To: to,
      };
    });

    return result;
  } else {
    console.error(response);
    throw Error("getClientTypeHistory error");
  }
}

// ----------------------------------------------------------------------------

export async function setClientTypeHistory(
  parms: AuditedCommandParams<ClientTypeHistoryEntry[]>,
) {
  const response = await axios.post<any>(
    formUrl("SetClientTypeHistory"),
    parms,
  );
  if (response.status != 200) {
    console.error(response);
    throw Error("setClientTypeHistory error");
  }
}

// ----------------------------------------------------------------------------

export async function getClientManagerHistory(
  clientId: number,
  clientManagerType: ClientManagerType,
): Promise<ClientManagerHistoryEntry[]> {
  const url = formQuery("GetClientManagerHistory", {
    clientId,
    clientManagerType,
  });

  const response = await axios.get<any>(url);

  if (response.status == 200) {
    return response.data.map((item: any) => {
      const to = item.To ? new Date(Date.parse(item.To)) : null;
      return {
        Id: item.Id,
        ClientManager: {
          Id: item.ClientManager.Id,
          Group: item.ClientManager.Group,
          FullName: item.ClientManager.FullName,
          Email: item.ClientManager.Email,
        },
        From: new Date(Date.parse(item.From)),
        To: to,
        Transferred: item.ClientManager.Transferred,
      };
    });
  } else {
    console.error(response);
    throw Error("getClientManagerHistory error");
  }
}

// ----------------------------------------------------------------------------

export async function setClientManagerHistory(
  parms: AuditedCommandParams<ClientManagerHistoryEntry[]>,
) {
  const response = await axios.post<any>(
    formUrl("SetClientManagerHistory"),
    parms,
  );
  if (response.status != 200) {
    console.error(response);
    throw Error("setClientManagerHistory error");
  }
}

// ----------------------------------------------------------------------------

export async function getInHouseClients(
  clientManagerType: string,
  startsWith: string,
): Promise<Client[]> {
  const url = formQuery("InHouseClients", { clientManagerType, startsWith });
  const response = await axios.get<any>(url);

  if (response.status == 200) {
    return response.data as Client[];
  } else {
    console.error(response);
    throw Error("getInHouseClients error");
  }
}

// ----------------------------------------------------------------------------

export async function addClientToClientManager(
  parms: AuditedCommandParams<ClientToClientManagerParams>,
): Promise<void> {
  const response = await axios.post<any>(
    formUrl("AddClientToClientManager"),
    parms,
  );
  if (response.status != 200) {
    console.error(response);
    throw Error("addClientToClientManager error");
  }
}

// ----------------------------------------------------------------------------

export async function getClientAudit(
  clientId: number,
): Promise<ClientCommissionAudit[]> {
  const url = formQuery("GetClientAudit", { clientId });
  const response = await axios.get<any>(url);

  if (response.status == 200) {
    return response.data as ClientCommissionAudit[];
  } else {
    console.error(response);
    throw Error("getClientAudit error");
  }
}

// ----------------------------------------------------------------------------

export async function getClientManagerAudit(
  clientManagerId?: number,
): Promise<ClientCommissionAudit[]> {
  clientManagerId ??= 0;
  const url = formQuery("GetClientManagerAudit", { clientManagerId });
  const response = await axios.get<any>(url);

  if (response.status == 200) {
    return response.data as ClientCommissionAudit[];
  } else {
    console.error(response);
    throw Error("getClientManagerAudit error");
  }
}

// ----------------------------------------------------------------------------

export async function setClientManagerInfo(
  parms: AuditedCommandParams<ClientManagerInfoParams>,
): Promise<void> {
  const response = await axios.post<any>(
    formUrl("SetClientManagerInfo"),
    parms,
  );
  if (response.status != 200) {
    console.error(response);
    throw Error("setClientManagerInfo error");
  }
}

// ----------------------------------------------------------------------------
