
import { defineComponent, ref } from "vue";
import { Input, TextArea, RadioGroup, Checkbox, NumericTextBox} from "@progress/kendo-vue-inputs";
import { ButtonGroup, Button } from "@progress/kendo-vue-buttons";
import { Grid /*, GridColumn*/ } from '@progress/kendo-vue-grid';

declare var $: any;
const daysBefore = 5;
const daysAfter = 10;
const orderGroups = {
  web: {
    id: "web",
    title: "Web Orders",
    types: {
      prn: { id: "prn", title: "Print only" },
      frm: { id: "frm", title: "Contains Framing" },
      mnt: { id: "mnt", title: "Contains Mounting" },
    },
  },
  asf: {
    id: "asf",
    title: "ASF",
    types: {
      prn: { id: "prn", title: "Print only" },
      frm: { id: "frm", title: "Contains ASF Framing" },
      mnt: { id: "mnt", title: "Contains ASF Mounting" },
    },
  },
  // rather never happens, but jic
  other: {
    id: "other",
    title: "Other",
    types: {
      prn: { id: "prn", title: "Print only" },
      frm: { id: "frm", title: "Contains Framing" },
      mnt: { id: "mnt", title: "Contains Mounting" },
    },
  },
};
const warehouseBranches = {
  UK: { id: "uk", name: "UK" },
  USA: { id: "us", name: "USA" },
  Germany: { id: "de", name: "Germany" },
};
const filterOptions = [
  { value: 0, label: "Orders" },
  { value: 1, label: "Order Items" }
];

let shutdownDatesCache: any = [];

function getToday() {
  const date = new Date(new Date().toISOString().split("T")[0]);
  return date;
}
function getNextDate(date: Date, isBackDirection = false) {
  const result = new Date(date);
  result.setDate(result.getDate() + (isBackDirection ? -1 : 1));
  return result;
}
function isWorkingDate(date: Date, warehouseId: string) {
  const dayOfWeek = date.getDay();
  return dayOfWeek != 6 && dayOfWeek != 0 // not Sat nor Sun
        && !shutdownDatesCache.find((x: any) => x.Warehouse == warehouseId && x.Date == date);
}
function buildColumnsBase() {
  const days = Array.from({ length: daysAfter + daysBefore + 1 });
  const columns = [
    {
      field: "title",
      title: " ",
      className: "order-overdue-title",
      headerClassName: "order-overdue-title",
    },
    ...days.map((_, i) => {
      return {
        headerCell: 'headerTemplate',
        field:
          "d" + (i < daysBefore ? daysBefore - i + "m" : i - daysBefore),
        isOff: false,
        date: '...',
        title:
          i < daysBefore
            ? -(daysBefore - i) + (i == 0 ? "+" : "")
            : i == daysBefore
            ? "Today"
            : i - daysBefore + (i == days.length - 1 ? "+" : ""),
        //headerAttributes: { title: "title" },
        //headerCell: headerCellFunction,
        className:
          "order-overdue-" +
          (i < daysBefore
            ? "past"
            : i == daysBefore
            ? "today"
            : i - daysBefore + "days"),
        headerClassName:
          "order-overdue-" +
          (i < daysBefore
            ? "past"
            : i == daysBefore
            ? "today"
            : i - daysBefore + "days"),
      };
    }),
  ];
  return columns;
}
export default defineComponent({
  name: "OrdersOverdueTab",
  components: {
    KRadioGroup: RadioGroup,
    KButton: Button,
    "kendo-grid": Grid,
  },
  setup() {
    const grid: any = ref(null);
    return { grid };
  },
  data() {
    return {
      filterOptions: filterOptions,
      noRecordsMessage: "No overdue orders",
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Age', value: 'age' },
      ],
      itemsUk: [] as any[],
      itemsDe: [] as any[],
      itemsUs: [] as any[],
      isLoading: false,
      filterBy: filterOptions[0].value,
      cache: null as any,
      todayDateUtc: getToday()
    };
  },
  computed: {
    columnsUk() {
      const columns = buildColumnsBase();
      this.initializeForWarehouse(columns, warehouseBranches.UK.id);
      return columns;
    },
    columnsDe() {
      const columns = buildColumnsBase();
      this.initializeForWarehouse(columns, warehouseBranches.Germany.id);
      return columns;
    },
    columnsUs() {
      const columns = buildColumnsBase();
      this.initializeForWarehouse(columns, warehouseBranches.USA.id);
      return columns;
    },
  },
  watch: {
    filterBy(filterOption) {
      if (this.isLoading) return;

      this.buildData();
    },
  },
  async mounted() {
    this.generateBackgroundClasses();
    await this.load();
  },
  methods: {
    getColumnTitle(props: any) {
      //console.log(props)
      return props.date;
    },
    initializeForWarehouse(columns: any[], warehouseId: string) {
      const currentDate = columns[daysBefore + 1]; // +1 because of the row title
      currentDate.isOff = !isWorkingDate(this.todayDateUtc, warehouseId);
      const d = this.todayDateUtc;
      currentDate.date = d.toLocaleDateString("en-US", { year: 'numeric', month: '2-digit', day: '2-digit' });
      currentDate.headerAttributes = { title: currentDate.date };

      if (currentDate.isOff) {
        currentDate.className += " is-dayoff";
        currentDate.headerClassName += " is-dayoff";
      }

      let columnDate = this.todayDateUtc;
      for (let i = daysBefore - 1; i >= 0; i--) {
        do {
          columnDate = getNextDate(columnDate, true);
        } while (!isWorkingDate(columnDate, warehouseId));
        columns[i + 1].date = columnDate;
      }
      columnDate = this.todayDateUtc;
      for (let i = daysBefore + 1; i <= daysBefore + daysAfter; i++) {
        do {
          columnDate = getNextDate(columnDate, false);
        } while (!isWorkingDate(columnDate, warehouseId));
        columns[i + 1].date = columnDate;
      }
    },
    rowRender(h: any, trElement: any, defaultSlots: any, props: any) {
      const isTitle = props.dataItem.isGroupTitle;
      const isTotal = props.dataItem.isTotal;

      const trProps = {
        class: isTitle ? "order-row-header" : isTotal ? "order-row-total" : null,
      };

      //if (isTitle && props.dataItem.date)
      //  defaultSlots[0].el.title = props.dataItem.date;

      return h("tr", trProps, defaultSlots);
    },
    buildData() {
      const data = this.cache;

      const filterByOrderOptions = this.filterBy == filterOptions[1].value;

      this.itemsUk = buildDataRows(data.uk);
      this.itemsDe = buildDataRows(data.de);
      this.itemsUs = buildDataRows(data.us);

      function buildDataRows(warehouseData: any): any[] {
        const result: any[] = [];
        if (!warehouseData) return result;

        const totalDataRow: any = { title: "Total", isTotal: true };

        Object.entries(orderGroups).forEach(group => {
          const orderTypeKey = group[0];
          const orderType = warehouseData[orderTypeKey];
          if (orderType) {
            result.push({ title: group[1].title, isGroupTitle: true });

            Object.entries(group[1].types).forEach(subGroup => {
              const orderSubTypeKey = subGroup[0];
              const orderSubType = orderType[orderSubTypeKey];
              if (orderSubType) {
                const calculatedDays = Object.keys(orderSubType).reduce((days: { [key: string]: { Id: number; Name: string }[] }, dayFieldName: string) => {
                  const dayData = orderSubType[dayFieldName];
                  const itemsPerDay = filterByOrderOptions 
                    ? dayData.reduce((acc: number, item: any) => acc + item.ItemsCount, 0)
                    : dayData.length;
                  days[dayFieldName] = itemsPerDay;

                  let totalPerDay = totalDataRow[dayFieldName] || 0;
                  totalPerDay += itemsPerDay;
                  totalDataRow[dayFieldName] = totalPerDay;

                  return days;
                }, {});
                const objToPush = { title: subGroup[1].title, ...calculatedDays };
                result.push(objToPush);
              }
            });
          }
        }, {});

        result.push(totalDataRow);
        return result;
      }

      // console.log(this.itemsUk);
      // console.log(this.itemsDe);
      // console.log(this.itemsUs);

      // console.log(data);
    },
    generateBackgroundClasses() {
      const styleSheet = document.styleSheets[0] || document.createElement("style");
      if (!styleSheet.ownerNode) document.head.appendChild(styleSheet.ownerNode!);

      for (let i = 1; i <= daysAfter; i++) {
        const opacity1 = (i / daysAfter * 0.1).toFixed(2); // Gradual opacity
        const opacity2 = (i / daysAfter * 0.2).toFixed(2); // Gradual opacity
        const className = `.order-overdue-${i}days`;
        const rule = `${className} { text-align:center!important; padding-left: 0!important; padding-right: 0!important; background: linear-gradient(-45deg, rgba(0,0,0,${opacity1}), rgba(0, 0, 0, ${opacity2})); }`;
        styleSheet.insertRule(rule, styleSheet.cssRules.length);
      }
    },
    refresh() {
      if (this.isLoading) return;
      this.load();
    },
    async load() {
      this.isLoading = true;

      try {
        if (!shutdownDatesCache) {
          alert('start')
          await $.get("").then((response: any) => {
            shutdownDatesCache = response;
          });
          alert(shutdownDatesCache);
        }
let self = this;

        $.get("/form/pms/GetOverdueOrdersStats").then((response: any) => {
          //response = [response[response.length - 1]];
          this.itemsUk = this.itemsDe = this.itemsUs;

          this.todayDateUtc = getToday();

          function getDaysDifferenceNormalized(baseDate: Date, dateToCheck: Date, warehouseId: string): number {
//console.log(dateToCheck)
//baseDate = new Date(baseDate.getFullYear(), baseDate.getMonth(), baseDate.getDate())
//dateToCheck = new Date(dateToCheck.getFullYear(), dateToCheck.getMonth(), dateToCheck.getDate());

            if (dateToCheck.getTime() === baseDate.getTime()) // displaying "today" orders anyways (whether today is day Off or not)
              return 0;

            const isBaseDateOff = !isWorkingDate(baseDate, warehouseId);

            if (dateToCheck.getTime() < baseDate.getTime()) {
              let nearestBaseDateOn = baseDate; // to obtain the nearest working base date (in case if today is Off)
              while (!isWorkingDate(nearestBaseDateOn, warehouseId))
                nearestBaseDateOn = getNextDate(nearestBaseDateOn, true);

              let testDate = nearestBaseDateOn;
              let daysDifference = isBaseDateOff ? -1 : 0;
              while (testDate.getTime() != dateToCheck.getTime() && daysDifference > -15) {
                testDate = getNextDate(testDate, true);
                if(isWorkingDate(testDate, warehouseId))
                  daysDifference--;
              }
              return daysDifference;
            } else {
              let nearestBaseDateOn = baseDate; // to obtain the nearest working base date (in case if today is Off)
              while (!isWorkingDate(nearestBaseDateOn, warehouseId))
                nearestBaseDateOn = getNextDate(nearestBaseDateOn, false);

              let testDate = nearestBaseDateOn;
              let daysDifference = isBaseDateOff ? 1 : 0;
              while (testDate.getTime() != dateToCheck.getTime() && daysDifference < 15) {
                testDate = getNextDate(testDate, false);
                if(isWorkingDate(testDate, warehouseId))
                  daysDifference++;
                //console.log(testDate.getTime() == dateToCheck.getTime(), testDate, dateToCheck, daysDifference)
              }
              return daysDifference;
              // if(!isDateOn(testDate, warehouseId)) // if the due date was (probably mistakenly) set to a day off, then adding one more delayed business day (if due passed)
              //   daysDifference++;

              // let isWorkingDay = true;
              // do {
              //   dateToCheck.setDate(dateToCheck.getDate() - 1);
              //   const dayOfWeek = dateToCheck.getDay();
              //   isWorkingDay = dayOfWeek != 6 && dayOfWeek != 0 // not Sat nor Sun
              //     && !shutdownDatesCache.find((x: any) => x.Warehouse == warehouseId && x.Date == dateToCheck);
              // } while (!isWorkingDay)

            }
            // const differenceInMilliseconds = dateToCheck.getTime() - baseDate.getTime();
            // const diff = differenceInMilliseconds / (1000 * 60 * 60 * 24);

            // // console.log(date2 + ' - ' + date1 + ' = ' + diff);
            // return diff;
          }

          // function convertActualToGridDifference(diff: number, warehouseId: string) {
            
          //   const warehouse = diffsGrid[warehouseId];

          //   const gridDiff = warehouse[diff];
          //   if (gridDiff === undefined) {
          //     if (diff < 0) 
          //   }
          // }

          // const groupedDays = response.Result.reduce((acc: { [key: string]: { Id: number; Name: string }[] }, order: any) => {
          function getDateId(orderDueDate: string, warehouseId: string) {
            const dueDate = new Date(orderDueDate.split("T")[0]);
            const normalizedDifference = getDaysDifferenceNormalized(self.todayDateUtc, dueDate, warehouseId);
            //const actualDifference = convertActualToGridDifference(actualDifference, warehouseId);

            const id = "d" 
              + (normalizedDifference < 0 
                ? (Math.abs(normalizedDifference) > daysBefore ? daysBefore : (Math.abs(normalizedDifference))) + "m"
                : normalizedDifference > daysAfter ? daysAfter : normalizedDifference);

            //console.log(normalizedDifference + ' : ' + id)

            return id;
          }

          const data: Record<string, any> = {};
          response.forEach((order: any) => {
            const warehouse = order.Branch == warehouseBranches.USA.name 
              ? warehouseBranches.USA.id 
              : order.Branch == warehouseBranches.Germany.name 
                ? warehouseBranches.Germany.id : warehouseBranches.UK.id;

            const webOrderTypes: string[] = ["hub", "order", "inHouse", "IllustrationSamplePack", "samplePack"];
            const type = webOrderTypes.includes(order.OrderType) ? "web" : order.OrderType == "ecommerce" ? "asf" : "other";
            const subType = order.ContainsFraming ? "frm" : order.ContainsMounting ? "mnt" : "prn";

            var w = data[warehouse];
            if (w == null) data[warehouse] = w = {};

            var t = w[type];
            if (t == null) w[type] = t = {};

            var sub = t[subType];
            if (sub == null) t[subType] = sub = {};

            var dateId = getDateId(order.DueDate, warehouse);
            var d = sub[dateId];
            if (d == null) sub[dateId] = d = [];
            d.push(order);
          });
console.log(data)
          this.cache = data;
          this.buildData();
        });
      } finally {
        this.isLoading = false;
      }
      return;
    },
    // filterChange(event: any) {
    //   this.items = this.filterData(event.filter);
    // },
    // filterData(filter: any) {
    //   const data = this.innerItems.slice();
    //   return filterBy(data, filter);
  },
  //},
});
